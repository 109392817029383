import * as React from "react"
import { SES_BASE_URL } from "../shared/consts"
import { CLASS_OPTIONS } from "../shared/classConsts/hello-framer-x"
import template2 from "../../src/handlers/sendMail/html-emails/after-webinar-checkout"
import template3 from "../../src/handlers/sendMail/html-emails/after-fkm-checkout"
import template0 from "../../src/handlers/sendMail/html-emails/after-hf-checkout"

const name = "테스트"
const productKey = "workshopNo"
// const productKey = "weekendB"

const Page = () => {
  return (
    <div>
      Mail Tempalte Page
      <br />
      <div
        dangerouslySetInnerHTML={{
          __html: template3(name, productKey),
        }}
      ></div>
    </div>
  )
}

export default Page
